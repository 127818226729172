<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import PaginationModule from './modules/PaginationModule.vue';
import moment from "moment/moment";
import CommonUtil from '../libs/CommonUtil';
import Mixin from '../libs/Mixin';
import {
    DEFAULT_LINE_CNT,
    DEFAULT_PAGE_NO,
    TRAFFIC_TYPE_SELL
} from '../libs/Mutations';

interface IState {
    isTabActive: string|null,
    bidPageNo: number,
    bidLineCnt: number,
    bidSortKey: string,
    bidSortType: string,
    watchListPageNo: number,
    watchListLineCnt: number,
    watchListSortKey: string,
    watchListSortType: string,
    checkedAucItemId: string[],
    checkedSelectAll: boolean,
    isWatchListModal: boolean,
    isHoldStatus: number,
    defaultColumn: string,
    domainColumn: string,
    statusColumn: string,
    bidSortLabels: ILabels,
    watchListSortLabels: ILabels,
    offerListSortLabels: ILabels,
    winBidSortLabels: ILabels,
    offerListPageNo: number,
    offerListLineCnt: number,
    offerListSortKey: string,
    offerListSortType: string,
    winBidPageNo: number,
    winBidLineCnt: number,
    winBidSortKey: string,
    winBidSortType: string,
    selectedItemId: any,
    isBidDescribe: boolean,
    isShowRibbonBidlist: boolean,
    isShowRibbonWatchlist: boolean,
}

interface ILabels {
    [key: string]: string;
}

let bidSortLabels: ILabels = {};
let watchListSortLabels: ILabels = {};

if (CommonUtil.router.market === 'jp') {
    bidSortLabels = {
        domainNameMl: 'ドメイン',
        currentPrice: '価格',
        bids: '入札',
        endDate: '残り',
    };
    watchListSortLabels = {
        domainNameMl: 'ドメイン',
        currentPrice: '価格',
        bids: '入札',
        endDate: '残り',
    };
} else if(CommonUtil.router.market === 'other') {
    bidSortLabels = {
        domainNameMl: 'ドメイン名',
        currentPrice: '価格',
        bids: '入札数',
        endDate: '残り',
    };
    watchListSortLabels = {
        domainNameMl: 'ドメイン名',
        currentPrice: '価格',
        bids: '入札数',
        endDate: '残り',
    };
} else {
    bidSortLabels = {
        domainNameMl: 'ドメイン名',
        currentPrice: '現在価格',
        bids: '入札数',
        endDate: '終了日',
    };
    watchListSortLabels = {
        domainNameMl: 'ドメイン名',
        currentPrice: '現在価格',
        bids: '入札数',
        endDate: '終了日',
    };
}

const offerListSortLabels: ILabels = {
    domainNameMl: 'ドメイン名',
    offerPrice: 'オファー価格',
    endDate: '終了日',
    statusname: '状況',
    reOfferDate: '拒否日時',
    reOfferPrice: '出品者からの提示額',
};

const winBidSortLabels: ILabels = {
    domainNameMl: 'ドメイン名',
    aucTrafficTypeId: '種類',
    currentPrice: '購入価格',
    endDate: '終了日',
};

let isSetHistory: boolean = false;
let isShowRibbonBidlist: boolean = false;
let isShowRibbonWatchlist: boolean = false;

const defaultTabActive: string = '1';

let isTabActive: string|null = null;

if (CommonUtil.router.detail === 'watch-list') {
    if (CommonUtil.router.market === 'other') {
        isTabActive = '6';
    } else {
        isTabActive = '2';
    }
} else if (CommonUtil.router.detail === 'bid-list') {
    if (CommonUtil.router.market === 'other') {
        isTabActive = '5';
    } else {
        isTabActive = defaultTabActive;
    }
} else if (CommonUtil.router.detail === 'offer-list') {
    isTabActive = '3';
} else if (CommonUtil.router.detail === 'win-bid-list') {
    isTabActive = '4';
}

export default defineComponent({
    mixins: [Mixin],
    components: {
        PaginationModule
    },
    setup() {
        const state = reactive<IState>({
            isTabActive: isTabActive,
            bidPageNo: DEFAULT_PAGE_NO,
            bidLineCnt: DEFAULT_LINE_CNT,
            bidSortKey: 'endDate',
            bidSortType: '1',
            watchListPageNo: DEFAULT_PAGE_NO,
            watchListLineCnt: DEFAULT_LINE_CNT,
            watchListSortKey: 'domainNameMl',
            watchListSortType: '1',
            checkedAucItemId: [],
            checkedSelectAll: false,
            isWatchListModal: false,
            isHoldStatus: 1,
            defaultColumn: 'dac-panel-table__heading',
            domainColumn: 'dac-panel-table__heading--domain',
            statusColumn: 'dac-panel-table__heading--btn',
            bidSortLabels: bidSortLabels,
            watchListSortLabels: watchListSortLabels,
            offerListSortLabels: offerListSortLabels,
            winBidSortLabels: winBidSortLabels,
            offerListPageNo: DEFAULT_PAGE_NO,
            offerListLineCnt: DEFAULT_LINE_CNT,
            offerListSortKey: 'domainNameMl',
            offerListSortType: '1',
            winBidPageNo: DEFAULT_PAGE_NO,
            winBidLineCnt: DEFAULT_LINE_CNT,
            winBidSortKey: 'domainNameMl',
            winBidSortType: '1',
            selectedItemId: null,
            isBidDescribe: false,
            isShowRibbonBidlist: isShowRibbonBidlist,
            isShowRibbonWatchlist: isShowRibbonWatchlist,
        });

        return {
            ...toRefs(state)
        };
    },
    created() {
        if (this.isTabActive === null) {
            if(CommonUtil.router.market === 'other') {
                this.isTabActive = '5';
            } else {
                this.isTabActive = defaultTabActive;
            }
        }
    },
    mounted() {
        if (this.isTabActive === '1') {
            setTimeout(() => {
                this.isShowRibbonBidlist = true;
            }, 1000);
            setTimeout(() => {
                this.isShowRibbonBidlist = false;
            }, 6000);
        } else {
            this.isShowRibbonBidlist = false;
        }
        if (this.isTabActive === '2') {
            setTimeout(() => {
                this.isShowRibbonWatchlist = true;
            }, 1000);
            setTimeout(() => {
                this.isShowRibbonWatchlist = false;
            }, 6000);
        } else {
            this.isShowRibbonWatchlist = false;
        }
    },
    methods: {
        remainMinutes(timeleft: string): string {
            const matches: any = (/^(\d+)D(\d+)H(\d+)M(\d+)S$/.exec(timeleft));
            if (matches !== null) {
                const days: number = parseInt(matches[1], 10);
                const hours: number = parseInt(matches[2], 10);
                const minutes: number = parseInt(matches[3], 10);
                let remainMinutes: number = minutes;
                if (days > 0) {
                    remainMinutes += days * 24 * 60;
                }
                if (hours > 0) {
                    remainMinutes += hours * 60;
                }
                return remainMinutes.toString() + '分';
            }

            return '-';
        },
        makeParamsOfferList(): InterfaceListParams {
            const params: InterfaceListParams = {
                pageNo: this.offerListPageNo,
                lineCnt: this.offerListLineCnt
            };

            if (this.watchListSortKey) {
                params.sortKey = this.offerListSortKey;
                params.sortType = this.offerListSortType;
            }
            return params;
        },
        offerListChangePage(pageNo: number): void {
            if (!isNaN(pageNo) && pageNo > 0 && pageNo <= this.offerListTotalPage) {
                this.offerListPageNo = pageNo;

                this.$store.dispatch('getOfferList', this.makeParamsOfferList());
            }
        },
        sortOfferList(sortKey: string): void {
            if (this.offerListSortKey !== sortKey) {
                this.offerListSortKey = sortKey;
                this.offerListSortType = '1';
            } else {
                if (this.offerListSortType === '1') {
                    this.offerListSortType = '2';
                } else {
                    this.offerListSortType = '1';
                }
            }
            this.offerListPageNo = 1;
            this.$store.dispatch('getOfferList', this.makeParamsOfferList());
        },
        makeParamsWinBidList(): InterfaceListParams {
            const params: InterfaceListParams = {
                pageNo: this.winBidPageNo,
                lineCnt: this.winBidLineCnt
            };

            if (this.winBidSortKey) {
                params.sortKey = this.winBidSortKey;
                params.sortType = this.winBidSortType;
            }
            return params;
        },
        winBidListChangePage(pageNo: number): void {
            if (!isNaN(pageNo) && pageNo > 0 && pageNo <= this.winBidListTotalPage) {
                this.winBidPageNo = pageNo;

                this.$store.dispatch('getWinBidList', this.makeParamsWinBidList());
            }
        },
        sortWinBidList(sortKey: string): void {
            if (this.winBidSortKey !== sortKey) {
                this.winBidSortKey = sortKey;
                this.winBidSortType = '1';
            } else {
                if (this.winBidSortType === '1') {
                    this.winBidSortType = '2';
                } else {
                    this.winBidSortType = '1';
                }
            }
            this.winBidPageNo = 1;
            this.$store.dispatch('getWinBidList', this.makeParamsWinBidList());
        },
        trafficTypeLabel(trafficType: number) {
            if (trafficType === TRAFFIC_TYPE_SELL) {
                return '即決価格';
            }
            return 'オークション';
        },
        offerStatusLabel(status: string) {
            if (status === 'DENY') {
                return '交渉失敗';
            } else if (status === 'INITIAL') {
                return 'オファー中';
            }
            return '成功';
        },
        tabTrigger(num: string): void {
            this.isTabActive = num;
            if(num === '1') {
                setTimeout(() => {
                    this.isShowRibbonBidlist = true;
                }, 1000);
                setTimeout(() => {
                    this.isShowRibbonBidlist = false;
                }, 6000);
            } else {
                this.isShowRibbonBidlist = false;
            }
            if(num === '2') {
                setTimeout(() => {
                    this.isShowRibbonWatchlist = true;
                }, 1000);
                setTimeout(() => {
                    this.isShowRibbonWatchlist = false;
                }, 6000);
            } else {
                this.isShowRibbonWatchlist = false;
            }
        },
        hold(hold: number): string {
            if (hold === 0) {
                this.isHoldStatus = 0;
                return '他者様';
            }
            this.isHoldStatus = 1;
            return 'お客様';
        },
        bidChangePage(pageNo: number): void {
            if (!isNaN(pageNo) && pageNo > 0 && pageNo <= this.bidTotalPage) {
                this.bidPageNo = pageNo;
                const params: InterfaceListParams = {
                    pageNo: this.bidPageNo,
                    lineCnt: this.bidLineCnt
                };
                this.$store.dispatch('getBidList', this.makeParamsBid());
            }
        },
        sortBid(sortKey: string): void {
            if (this.bidSortKey !== sortKey) {
                this.bidSortKey = sortKey;
                this.bidSortType = '1';
            } else {
                if (this.bidSortType === '1') {
                    this.bidSortType = '2';
                } else {
                    this.bidSortType = '1';
                }
            }
            this.bidPageNo = 1;
            this.$store.dispatch('getBidList', this.makeParamsBid());
        },
        makeParamsBid(): InterfaceListParams {
            const params: InterfaceListParams = {
                pageNo: this.bidPageNo,
                lineCnt: this.bidLineCnt
            };

            if (this.bidSortKey) {
                params.sortKey = this.bidSortKey;
                params.sortType = this.bidSortType;
            }
            return params;
        },
        selectAll(e: any): void {
            const target = e.currentTarget;
            const checked = target.checked;
            if (checked === true) {
                this.watchListItems.forEach((item: any) => {
                    if (this.checkedAucItemId.indexOf(item.aucItemId) === -1) {
                        this.checkedAucItemId.push(item.aucItemId);
                    }
                });
            } else {
                this.checkedAucItemId = [];
            }
        },
        watchListChangePage(pageNo: number): void {
            if (!isNaN(pageNo) && pageNo > 0 && pageNo <= this.watchListTotalPage) {
                this.watchListPageNo = pageNo;
                const params: InterfaceListParams = {
                    pageNo: this.watchListPageNo,
                    lineCnt: this.watchListLineCnt
                };
                this.$store.dispatch('getWatchList', this.makeParamsWatchList());
            }
        },
        deleteWatchList(): void {
            this.$store.dispatch('deleteWatchList', this.checkedAucItemId).then((response: any) => {
                if (response.data.success) {
                    this.checkedAucItemId = [];
                    this.watchListPageNo = 1;
                    this.checkedSelectAll = false;
                }
            });
        },
        deleteWatchListItem(itemId: any = null): void {
            this.isWatchListModal = false;
            this.$store.dispatch('deleteWatchList', itemId).then((response: any) => {
                if (response.data.success) {
                    this.checkedAucItemId = [];
                    this.watchListPageNo = 1;
                    this.checkedSelectAll = false;
                }
            });
        },
        sortWatchList(sortKey: string): void {
            if (this.watchListSortKey !== sortKey) {
                this.watchListSortKey = sortKey;
                this.watchListSortType = '1';
            } else {
                if (this.watchListSortType === '1') {
                    this.watchListSortType = '2';
                } else {
                    this.watchListSortType = '1';
                }
            }
            this.watchListPageNo = 1;
            this.$store.dispatch('getWatchList', this.makeParamsWatchList());
        },
        makeParamsWatchList(): InterfaceListParams {
            const params: InterfaceListParams = {
                pageNo: this.watchListPageNo,
                lineCnt: this.watchListLineCnt
            };

            if (this.watchListSortKey) {
                params.sortKey = this.watchListSortKey;
                params.sortType = this.watchListSortType;
            }
            return params;
        },
        scrToggle(e: any): void {
            e.preventDefault();
        },
        deleteWatchListModal(itemId: null|number = null): void {
            this.isWatchListModal = !this.isWatchListModal;

            if (null !== itemId) {
                this.selectedItemId = itemId;
            }

            let body = document.body;
            let fixedClass = 'is-fixed';
            let scrEvent = `onwheel` in document ? `wheel` : `onmousewheel` in document ? `mousewheel` : `DOMMouseScroll`;

            if (this.isWatchListModal === true) {
                body.classList.add(fixedClass);
                if (body.classList.contains(fixedClass)) {
                    if(window.innerWidth > 767) { // PC
                        body.addEventListener(scrEvent, this.scrToggle, {
                            capture: false,
                            passive: false
                        });
                        body.removeEventListener(`touchstart`, this.scrToggle, false);
                    } else { // SP
                        body.removeEventListener(scrEvent, this.scrToggle, false);

                        body.addEventListener(`touchstart`, this.scrToggle, true);
                    }

                    window.onresize = () =>{
                        if(window.innerWidth > 767) { // PC
                            body.addEventListener(scrEvent, this.scrToggle, {
                                capture: false,
                                passive: false
                            });
                            body.removeEventListener(`touchstart`, this.scrToggle, false);
                        } else { // SP
                            body.removeEventListener(scrEvent, this.scrToggle, false);

                            body.addEventListener(`touchstart`, this.scrToggle, false);
                        }
                    };
                }

            } else {
                body.classList.remove(fixedClass);
                body.removeEventListener(scrEvent, this.scrToggle, false);
                body.removeEventListener(`touchstart`, this.scrToggle, false);
            }
        },
        modifierClass(sortLabel: string): string {
            if(sortLabel === 'ドメイン名' || sortLabel === 'ドメイン') {
                return this.domainColumn;
            } else if(sortLabel === '状況') {
                return this.statusColumn;
            } else {
                return this.defaultColumn;
            }
        },
        bidModalActive(): void {
            this.isBidDescribe = !this.isBidDescribe;

            let body = document.body;
            let fixedClass = 'is-fixed';
            let scrEvent = `onwheel` in document ? `wheel` : `onmousewheel` in document ? `mousewheel` : `DOMMouseScroll`;

            if (this.isBidDescribe === true) {
                body.classList.add(fixedClass);
                if (body.classList.contains(fixedClass)) {
                    if(window.innerWidth > 767) { // PC
                        body.addEventListener(scrEvent, this.scrToggle, {
                            capture: false,
                            passive: false
                        });
                        body.removeEventListener(`touchstart`, this.scrToggle, false);
                    } else { // SP
                        body.removeEventListener(scrEvent, this.scrToggle, false);

                        body.addEventListener(`touchstart`, this.scrToggle, true);
                    }

                    window.onresize = () =>{
                        if(window.innerWidth > 767) { // PC
                            body.addEventListener(scrEvent, this.scrToggle, {
                                capture: false,
                                passive: false
                            });
                            body.removeEventListener(`touchstart`, this.scrToggle, false);
                        } else { // SP
                            body.removeEventListener(scrEvent, this.scrToggle, false);

                            body.addEventListener(`touchstart`, this.scrToggle, false);
                        }
                    };
                }

            } else {
                body.classList.remove(fixedClass);
                body.removeEventListener(scrEvent, this.scrToggle, false);
                body.removeEventListener(`touchstart`, this.scrToggle, false);
            }
        },
        isItemTimeLeftLimit(timeleft: any):boolean {
            const matches: any = (/^(\d+)D(\d+)H(\d+)M(\d+)S$/.exec(timeleft));
            let day = matches[1];
            if (day === '0') {
                return true;
            }
            return false;
        },
        convertItemTimeLeft(timeleft: any): any {
            const matches: any = (/^(\d+)D(\d+)H(\d+)M(\d+)S$/.exec(timeleft));
            let hour = matches[2];
            let minute = matches[3];
            let seconds = matches[4];

            if (hour < 10) {
                hour = '0' + hour;
            }
            if (minute < 10) {
                minute = '0' + minute;
            }
            if (seconds < 10) {
                seconds = '0' + seconds;
            }

            if (matches !== null) {
                return (hour + ':' + minute + ':' + seconds);
            }
            return null;
        },
        convertTimeLeft(timeleft: any): any {
            const matches: any = (/^(\d+)D(\d+)H(\d+)M(\d+)S$/.exec(timeleft));
            if (matches !== null) {
                return {
                    day: matches[1],
                    hour: matches[2],
                    minute: matches[3],
                    seconds: matches[4]
                };
            }
            return null;
        },
        fireEvent(): void {
            CommonUtil.events('event517');
        }
    },
    computed: {
        showEndDateNotice(): boolean {
            // 終了時間から24時間まえまで表示する
            if (CommonUtil.isString(this.$store.state.Market.endDate)) {
                const endDate: any = moment(this.$store.state.Market.endDate);
                const now: any = moment(this.$store.state.Common.date);
                const diff: number = endDate.diff(now, 'seconds');
                if (diff <= 24 * 3600 && diff >= 0) {
                    return true;
                }
            }
            return false;
        },
        showEndDate(): boolean {
            if (CommonUtil.isString(this.$store.state.Market.endDate)) {
                const now: any = moment(this.$store.state.Common.date);
                const startDate: any = moment(this.$store.state.Common.date).startOf('month').add(12, 'hours');
                const endDate: any = moment(this.$store.state.Market.endDate);
                const diffStart: number = now.diff(startDate, 'seconds');
                const diffEnd: number = endDate.diff(now, 'seconds');
                // 一日の12:00 〜 終了の24時間前
                if (diffStart >= 0 && diffEnd > 24 * 3600) {
                    return true;
                }
            }
            return false;
        },
        endDate(): any {
            if (CommonUtil.isString(this.$store.state.Market.endDate)) {
                const endDate: any = moment(this.$store.state.Market.endDate);
                return endDate.locale('ja').format('MM月DD日(ddd)HH:mm');
            }

            return null;
        },
        watchListItems(): any[] {
            if (CommonUtil.isObject(this.$store.state.WatchList.list) && CommonUtil.isArray(this.$store.state.WatchList.list.items)) {
                return this.$store.state.WatchList.list.items;
            }
            return [];
        },
        watchListTotalPage(): any {
            if (CommonUtil.isObject(this.$store.state.WatchList.list)) {
                const totalCount: number = parseInt(this.$store.state.WatchList.list.totalCount, 10);
                const totalPage: number = Math.ceil(totalCount / DEFAULT_LINE_CNT);
                return totalPage;
            }
            return 0;
        },
        bidListItems(): any[] {
            if (CommonUtil.isObject(this.$store.state.Bid.list) && CommonUtil.isArray(this.$store.state.Bid.list.items)) {
                return this.$store.state.Bid.list.items;
            }
            return [];
        },
        bidTotalPage(): any {
            if (CommonUtil.isObject(this.$store.state.Bid.list)) {
                const totalCount: number = parseInt(this.$store.state.Bid.list.totalCount, 10);
                const totalPage: number = Math.ceil(totalCount / DEFAULT_LINE_CNT);
                return totalPage;
            }
            return 0;
        },
        winBidListItems(): any[] {
            if (CommonUtil.isObject(this.$store.state.WinBid.list) && CommonUtil.isArray(this.$store.state.WinBid.list.items)) {
                return this.$store.state.WinBid.list.items;
            }
            return [];
        },
        winBidListTotalPage(): any {
            if (CommonUtil.isObject(this.$store.state.WinBid.list)) {
                const totalCount: number = parseInt(this.$store.state.WinBid.list.totalCount, 10);
                const totalPage: number = Math.ceil(totalCount / DEFAULT_LINE_CNT);
                return totalPage;
            }
            return 0;
        },
        offerListItems(): any[] {
            if (CommonUtil.isObject(this.$store.state.Offer.list) && CommonUtil.isArray(this.$store.state.Offer.list.items)) {
                return this.$store.state.Offer.list.items;
            }
            return [];
        },
        offerListTotalPage(): any {
            if (CommonUtil.isObject(this.$store.state.Offer.list)) {
                const totalCount: number = parseInt(this.$store.state.Offer.list.totalCount, 10);
                const totalPage: number = Math.ceil(totalCount / DEFAULT_LINE_CNT);
                return totalPage;
            }
            return 0;
        },
        disabledDeleteWatchListButton(): boolean {
            return this.checkedAucItemId.length === 0;
        },
        pageName(): string {
            return (this.router.detail as string).replace(/-/g, '');
        },
        recommendItems(): any[] {
            // [Object Array]で返る
            if (CommonUtil.isArray(this.$store.state.RecommendList.data.items)) {
                return this.$store.state.RecommendList.data.items;
            }
            return [];
        },
    },
    watch: {
        isTabActive: {
            handler: function(value: string) {
                let detail: string = 'bid-list';
                if (value === '2' || value === '6') {
                    detail = 'watch-list';
                } else if (value === '3') {
                    detail = 'offer-list';
                } else if (value === '4') {
                    detail = 'win-bid-list';
                }
                const param: string = '?btn_id=' + this.router.market +  'auc_mypage_' + this.pageName + '_' + detail.replace(/-/g, '') + '_tab_201904';

                this.setRouterDetail(detail);

                const url: string = this.linkUrl('mypage') + '/' + detail + param;
                if (isSetHistory === false && !this.router.detail) {
                    if ('replaceState' in window.history) {
                        window.history.replaceState(null, document.title, url);
                    }
                    isSetHistory = true;
                } else {
                    if ('pushState' in window.history) {
                        window.history.pushState(null, document.title, url);
                    }
                }

                this.$nextTick(() => {
                    const title: HTMLTitleElement = document.head!.querySelector('title') as HTMLTitleElement;
                    let titleContent: string = title.innerText.replace(/^[^|]+/, '');
                    if (value === '2' || value === '6') {
                        titleContent = 'ウォッチリスト-マイページ';
                    } else if (value === '3') {
                        titleContent = '交渉中ドメイン-マイページ';
                    } else if (value === '4') {
                        titleContent = '申請履歴-マイページ';
                    } else {
                        titleContent = '入札中ドメイン-マイページ';
                    }
                    title.innerText = titleContent;
                    window.scrollTo(0, 0);
                });
            }
        }
    }
});
</script>

<template>
<div>
    <main class="dac-main">
        <article class="dac-article">
            <section class="dac-section is-pc">
                <div class="dac-section__inner--lg">
                    <nav class="dac-breadcrumb">
                        <ul class="dac-breadcrumb__list">
                            <li class="dac-breadcrumb__item" v-if="router.market === 'jp'">
                                <a class="dac-breadcrumb__link" :href="'/campaign/auction/?btn_id=jpauc_mypage_' + pageName + '_breadcrumb_top_201904'">オークショントップ</a>
                            </li>
                            <li class="dac-breadcrumb__item" v-if="router.market === 'other'">
                                <a class="dac-breadcrumb__link"  :href="'/campaign/gtldauction/?btn_id=otherauc_mypage_' + pageName + '_breadcrumb_top_202310'">
                                    オークショントップ
                                </a>
                            </li>
                            <li class="dac-breadcrumb__item" v-else-if="router.market === 'gtld'">
                                <a class="dac-breadcrumb__link" :href="'/campaign/gtldauction/?btn_id=gtldauc_mypage_' + pageName + '_breadcrumb_top_201904'">オークショントップ</a>
                            </li>
                            <li class="dac-breadcrumb__item" v-else-if="router.market === 'sell'">
                                <a class="dac-breadcrumb__link" :href="linkUrl('list?btn_id=sellauc_mypage_' + pageName + '_breadcrumb_top_201904')">ドメイン売買トップ</a>
                            </li>
                            <li class="dac-breadcrumb__item is-current" v-if="isTabActive === '1' || isTabActive === '5'">入札中ドメイン（マイページ）</li>
                            <li class="dac-breadcrumb__item is-current" v-else-if="isTabActive === '2' || isTabActive === '6'">ウォッチリスト（マイページ）</li>
                            <li class="dac-breadcrumb__item is-current" v-else-if="isTabActive === '3'">交渉中ドメイン（マイページ）</li>
                            <li class="dac-breadcrumb__item is-current" v-else-if="isTabActive === '4'">申請履歴（マイページ）</li>
                            <li class="dac-breadcrumb__item is-current" v-else>マイページ</li>
                        </ul>
                    </nav><!-- /.dac-breadcrumb -->
                </div>
            </section><!-- /.dac-section -->

            <div :class="['dac-banner-mypage']" v-if="router.market === 'gtld'">
                <template v-if="router.market === 'gtld'">
                    <img :src="assets('images/banner-gtld-mypage-sp.png')" alt="gtldドメインオークション マイページ" class="is-sp" />
                    <img :src="assets('images/banner-gtld-mypage-pc.png')" alt="gtldドメインオークション マイページ" class="is-pc" />
                </template>
            </div><!-- /.dac-main-banner -->
            <div class="is-pc">
                <p class="dac-text__caption" v-if="router.market === 'gtld' && router.detail === 'bid-list' || router.market === 'sell' && router.detail === 'bid-list'">オークション参加中ドメイン名の現状の最高入札者や現状価格を確認することができます。
                    <template v-if="router.market === 'jp'">
                        <br><a :href="'/auc/other/mypage/bid-list'" @click="onclickEvar('eVar10', 'jp_mypage_comnetlink')">.com.netオークションのマイページはコチラ</a>
                    </template>
                    <template v-if="router.market === 'other'">
                        <br><a :href="'/auc/jp/mypage/bid-list'" @click="onclickEvar('eVar10', 'comnet_mypage_jplink')">.jpオークションのマイページはコチラ</a>
                    </template>
                </p>
                <p class="dac-text__caption" v-if="router.market === 'sell' && router.detail === 'offer-list'">価格交渉をしたドメイン名のオファー価格や交渉状況を確認することができます。</p>
                <p class="dac-text__caption" v-if="router.market === 'sell' && router.detail === 'win-bid-list'">ドメイン売買サービスで取得したドメイン名を確認することができます。</p>
            </div>
            <div class="is-sp">
                <p class="dac-text__caption--left" v-if="router.market === 'gtld' && router.detail === 'bid-list' || router.market === 'sell' && router.detail === 'bid-list'">
                    オークション参加中ドメイン名の現状の最高入札者や現状価格を確認することができます。
                    <template v-if="router.market === 'jp'">
                        <br><a :href="'/auc/other/mypage/bid-list'" @click="onclickEvar('eVar10', 'jp_mypage_comnetlink')">.com.netオークションのマイページはコチラ</a>
                    </template>
                    <template v-if="router.market === 'other'">
                        <br><a :href="'/auc/jp/mypage/bid-list'" @click="onclickEvar('eVar10', 'comnet_mypage_jplink')">.jpオークションのマイページはコチラ</a>
                    </template>
                </p>
                <p class="dac-text__caption--left" v-if="router.market === 'gtld' && router.detail === 'watch-list' || router.market === 'sell' && router.detail === 'watch-list'">
                    ウォッチリストに追加したドメイン名を確認することができます。入札状況を確認しながら入札するドメイン名や入札金額をご検討ください。
                    <template v-if="router.market === 'jp'">
                        <br><a :href="'/auc/other/mypage/watch-list'" @click="onclickEvar('eVar10', 'jp_mypage_comnetlink')">.com.netオークションのマイページはコチラ</a>
                    </template>
                    <template v-if="router.market === 'other'">
                        <br><a :href="'/auc/jp/mypage/watch-list'" @click="onclickEvar('eVar10', 'comnet_mypage_jplink')">.jpオークションのマイページはコチラ</a>
                    </template>
                </p>
                <p class="dac-text__caption--left" v-if="router.market === 'sell' && router.detail === 'offer-list'">価格交渉をしたドメイン名のオファー価格や交渉状況を確認することができます。</p>
                <p class="dac-text__caption--left" v-if="router.market === 'sell' && router.detail === 'win-bid-list'">ドメイン売買サービスで取得したドメイン名を確認することができます。</p>
            </div>

            <section class="dac-section" v-if="showEndDateNotice && router.market === 'jp'">
                <div class="dac-section__inner--lg">
                    <div class="dac-banner-alert" v-if="showEndDateNotice && router.market === 'jp'">
                        <div class="dac-banner-alert__img">
                            <img :src="assets('images/img_little-time-sp.png')" alt="残り時間わずか！！" class="is-sp" />
                            <img :src="assets('images/img_little-time-pc.png')" alt="残り時間わずか！！" class="is-pc" />
                        </div>
                        <div class="dac-banner-alert__body is-sp">
                            <p class="dac-text--sm"><b>終了日時：<span class="dac-text__alert">{{ endDate }}まで</span></b></p>
                        </div>
                        <div class="dac-banner-alert__body is-pc">
                            <p class="dac-text--md"><b>終了日時：<span class="dac-text__alert">{{ endDate }}まで</span></b></p>
                        </div>
                    </div>
                </div><!-- /.dac-section__inner--lg -->
            </section><!-- /.dac-section -->
            <section class="dac-section">
                <div class="dac-section__inner--lg">
                    <div class="dac-note-point is-sp" v-if="showEndDate && router.market === 'jp'">
                        <div class="dac-note-point__inner--center">
                            <p class="dac-text--sm"><span class="dac-text__alert">【受付終了期限：{{ endDate }}】</span></p>
                        </div>
                    </div>
                    <ul class="dac-tab-mypage" v-if="router.market === 'jp'">
                        <li class="dac-tab-mypage__item" :class="{'is-active': isTabActive === '1'}" >
                            <a href="javascript: void(0);" class="dac-tab-mypage__link"  @click="tabTrigger('1');onclickEvar('eVar10', 'jpauc_mypage_tab_bidlist_202403')">
                                <span v-if="!isMobile">入札中ドメイン ①</span>
                                <span v-else>入札中<br>ドメイン ①</span>
                            </a>
                        </li>
                        <div class="dac-tab-ribbon" v-show="isShowRibbonBidlist">
                            ①のリストにドメインが無い場合は<br>
                            ②をご確認ください。
                        </div>
                        <li class="dac-tab-mypage__item" :class="{'is-active': isTabActive === '5'}">
                            <a :href="'/auc/other/mypage/bid-list'" class="dac-tab-mypage__link" @click="onclickEvar('eVar10', 'otherauc_mypage_tab_bidlist_202403')">
                                <span v-if="!isMobile">入札中ドメイン ②</span>
                                <span v-else>入札中<br>ドメイン ②</span>
                            </a>
                        </li>
                        <li class="dac-tab-mypage__item" :class="{'is-active': isTabActive === '2'}">
                            <a href="javascript: void(0);" class="dac-tab-mypage__link" @click="tabTrigger('2');onclickEvar('eVar10', 'jpauc_mypage_tab_watchlist_202403')">
                                <span v-if="!isMobile">ウォッチリスト ① </span>
                                <span v-else>ウォッチ<br>リスト ① </span>
                            </a>
                        </li>
                        <div class="dac-tab-ribbon--watch" v-show="isShowRibbonWatchlist">
                            ①のリストにドメインが無い場合は<br>
                            ②をご確認ください。
                        </div>
                        <li class="dac-tab-mypage__item" :class="{'is-active': isTabActive === '6'}">
                            <a :href="'/auc/other/mypage/watch-list'" class="dac-tab-mypage__link" @click="onclickEvar('eVar10', 'otherauc_mypage_tab_watchlist_202403')">
                                <span v-if="!isMobile">ウォッチリスト ②</span>
                                <span v-else>ウォッチ<br>リスト ②</span>
                            </a>
                        </li>
                    </ul><!-- /.dac-tab -->
                    <ul class="dac-tab-mypage" v-else-if="router.market === 'other'">
                        <li class="dac-tab-mypage__item" :class="{'is-active': isTabActive === '1'}" >
                            <a :href="'/auc/jp/mypage/bid-list'" class="dac-tab-mypage__link"  @click="onclickEvar('eVar10', 'jpauc_mypage_tab_bidlist_202403')">
                                <span v-if="!isMobile">入札中ドメイン ①</span>
                                <span v-else>入札中<br>ドメイン ①</span>
                            </a>
                        </li>
                        <div class="dac-tab-ribbon" v-show="isShowRibbonBidlist">
                            ①のリストにドメインが無い場合は<br>
                            ②をご確認ください。
                        </div>
                        <li class="dac-tab-mypage__item" :class="{'is-active': isTabActive === '5'}">
                            <a href="javascript: void(0);" class="dac-tab-mypage__link" @click="tabTrigger('5');onclickEvar('eVar10', 'otherauc_mypage_tab_bidlist_202403')">
                                <span v-if="!isMobile">入札中ドメイン ②</span>
                                <span v-else>入札中<br>ドメイン ②</span>
                            </a>
                        </li>
                        <li class="dac-tab-mypage__item" :class="{'is-active': isTabActive === '2'}">
                            <a :href="'/auc/jp/mypage/watch-list'" class="dac-tab-mypage__link" @click="onclickEvar('eVar10', 'jpauc_mypage_tab_watchlist_202403')">
                                <span v-if="!isMobile">ウォッチリスト ① </span>
                                <span v-else>ウォッチ<br>リスト ① </span>
                            </a>
                        </li>
                        <div class="dac-tab-ribbon--watch" v-show="isShowRibbonWatchlist">
                            ①のリストにドメインが無い場合は<br>
                            ②をご確認ください。
                        </div>
                        <li class="dac-tab-mypage__item" :class="{'is-active': isTabActive === '6'}">
                            <a href="javascript: void(0);" class="dac-tab-mypage__link" @click="tabTrigger('6');onclickEvar('eVar10', 'otherauc_mypage_tab_watchlist_202403')">
                                <span v-if="!isMobile">ウォッチリスト ②</span>
                                <span v-else>ウォッチ<br>リスト ②</span>
                            </a>
                        </li>
                    </ul><!-- /.dac-tab -->
                    <ul class="dac-tab" v-else>
                        <li class="dac-tab__item" :class="{'is-active': isTabActive === '1'}">
                            <template v-if="router.market === 'gtld'">
                                <a
                                    href="javascript: void(0);"
                                    class="dac-tab__link" @click="tabTrigger('1');onclickEvar('eVar10', router.market + 'auc_mypage_' + pageName + '_bidlist_tab_201904')"
                                >入札中ドメイン</a>
                            </template>
                            <template v-else>
                                <a href="javascript: void(0);" class="dac-tab__link" @click="tabTrigger('1');onclickEvar('eVar10', router.market + 'auc_mypage_' + pageName + '_bidlist_tab_201904')">入札中ドメイン</a>
                            </template>
                        </li>
                        <li class="dac-tab__item" :class="{'is-active': isTabActive === '2'}">
                            <template v-if="router.market === 'gtld'">
                                <a
                                    href="javascript: void(0);"
                                    class="dac-tab__link"
                                    @click="tabTrigger('2');onclickEvar('eVar10', router.market + 'auc_mypage_' + pageName + '_watchlist_tab_201904')"
                                >ウォッチリスト</a>
                            </template>
                            <template v-else>
                                <a href="javascript: void(0);" class="dac-tab__link" @click="tabTrigger('2');onclickEvar('eVar10', router.market + 'auc_mypage_' + pageName + '_watchlist_tab_201904')">ウォッチリスト</a>
                            </template>
                        </li>
                        <li v-if="roleId === '2' && router.market === 'sell'" class="dac-tab__item" :class="{'is-active': isTabActive === '3'}">
                            <a href="javascript: void(0);" class="dac-tab__link" @click="tabTrigger('3');onclickEvar('eVar10', router.market + 'auc_mypage_' + pageName + '_offerlist_tab_201904')">交渉中ドメイン</a>
                        </li>
                        <li v-if="roleId === '2' && router.market === 'sell'" class="dac-tab__item" :class="{'is-active': isTabActive === '4'}">
                            <a href="javascript: void(0);" class="dac-tab__link" @click="tabTrigger('4');onclickEvar('eVar10', router.market + 'auc_mypage_' + pageName + '_winbidlist_tab_201904')">申請履歴</a>
                        </li>
                    </ul><!-- /.dac-tab -->

                    <ul class="dac-tab-content">
                        <li class="dac-tab-content__item" v-if="isTabActive === '1' || isTabActive === '5'">
                            <div class="is-pc">
                                <div :class="['dac-panel', (0 === bidListItems.length) ? 'is-flat' : '']">
                                    <table class="dac-panel-table--sort">
                                        <thead class="dac-panel-table__header--primary">
                                        <tr>
                                            <th v-for="(sortLabel, key) in bidSortLabels" :class="modifierClass(sortLabel)">
                                                <template v-if="0 === bidListItems.length">
                                                    {{ sortLabel }}
                                                </template>
                                                <template v-else>
                                                    <a
                                                        :class="['dac-panel-table__trigger', bidSortType === '1' ? 'is-current' : '']"
                                                        href="javascript: void(0);"
                                                        @click="sortBid(key);onclickEvar('eVar10', router.market + 'auc_mypage_bidlist_sort_' + key + '_onclick_' + (router.market ==='other' ? '202310' : '201904'))"
                                                    >
                                                        {{ sortLabel }}
                                                        <i class="dac-ico-sort" v-if="bidSortKey === key"></i>
                                                    </a>
                                                </template>
                                            </th>
                                            <th class="dac-panel-table__heading--btn">最高入札者</th>
                                            <th class="dac-panel-table__heading--btn"></th>
                                        </tr>
                                        </thead>
                                        <tbody class="dac-panel-table__body">
                                        <tr v-for="item in bidListItems">
                                            <th class="dac-panel-table__content">

                                                <template v-if="router.market === 'jp'">
                                                <a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_domain_201904')"
                                                   class="dac-panel-table__id"
                                                >{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                                <p class="dac-text--sm"><span class="dac-text__alert">{{ item.means }}</span></p>
                                                </template>
                                                <template v-else>
                                                    <template v-if="router.market === 'jp' || router.market === 'gtld'">
                                                        <a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_domain_201904')"
                                                           class="dac-panel-table__id"
                                                        >{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                                    </template>
                                                    <template v-else-if="router.market === 'other'">
                                                        <a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_domain_201904')" class="dac-panel-table__id">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                                    </template>
                                                    <template v-else>
                                                        <a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_domain_201904')" class="dac-panel-table__id">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                                    </template>
                                                </template>
                                            </th>
                                            <td class="dac-panel-table__content">¥<span class="dac-panel-table__price">{{ numberFormat(item.currentPrice) }}</span></td>
                                            <td class="dac-panel-table__content"><span class="dac-panel-table__num">{{ numberFormat(item.bidCount) }}</span></td>
                                            <td class="dac-panel-table__content">
                                                <template v-if="router.market === 'jp' || router.market === 'other'">
                                                    <span class="dac-table-list__date" v-if="convertTimeLeft(item.timeleft)">
                                                                <template v-if="convertTimeLeft(item.timeleft).day !== '0'">
                                                                    {{ convertTimeLeft(item.timeleft).day }}<span class="dac-table-list__unit">日</span>
                                                                </template>
                                                                <template v-if="convertTimeLeft(item.timeleft).hour !== '0'">
                                                                    {{ convertTimeLeft(item.timeleft).hour }}<span class="dac-table-list__unit">時間</span>
                                                                </template>
                                                                <template v-if="convertTimeLeft(item.timeleft).minute !== '0'">
                                                                    {{ convertTimeLeft(item.timeleft).minute }}<span class="dac-table-list__unit">分</span>
                                                                </template>
                                                                <template v-if="convertTimeLeft(item.timeleft).seconds !== '0'">
                                                                    {{ convertTimeLeft(item.timeleft).seconds }}<span class="dac-table-list__unit">秒</span>
                                                                </template>
                                                            </span>
                                                    <span class="dac-table-list__date" v-else>-</span>
                                                </template>
                                                <template v-else>
                                                    <template v-if="
                                                                router.market !== 'other' ||
                                                                !isItemTimeLeftLimit(item.timeleft)
                                                            ">
                                                        <span class="dac-panel-table__num">{{ convertJpDateFormat(item.endDate) }}</span>
                                                        <span class="dac-panel-table__time">{{ convertJpTimeFormat(item.endDate) }}</span>
                                                    </template>
                                                    <div class="dac-panel-table__time--limit" v-if="
                                                                router.market === 'other' &&
                                                                isItemTimeLeftLimit(item.timeleft)
                                                            ">
                                                        <span>残り時間：</span>
                                                        <span class="dac-text__alert">{{ convertItemTimeLeft(item.timeleft) }}</span>
                                                    </div>
                                                </template>
                                            </td>
                                            <td class="dac-panel-table__content">
                                                <template v-if="item.hold === 0 ">
                                                        <span class="dac-panel-table__text is-hold">
                                                            <i class="dac-ico-lose"></i>
                                                            {{ hold(item.hold) }}
                                                        </span>
                                                </template>
                                                <template v-else-if="item.hold === 1 ">
                                                        <span class="dac-panel-table__text dac-text__winning">
                                                            <i class="dac-ico-win"></i>
                                                            {{ hold(item.hold) }}
                                                        </span>
                                                </template>
                                            </td>
                                            <td class="dac-panel-table__content">
                                                <template v-if="router.market === 'jp'">
                                                    <a
                                                        class="dac-btn-secondary--sm"
                                                        @click="fireEvent();onclickEvar('eVar10', router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        target="_blank"
                                                        rel="noopener"
                                                        v-if="item.hold === 1"
                                                    >更新する</a>
                                                    <a
                                                        class="dac-btn-secondary--sm"
                                                        @click="fireEvent();onclickEvar('eVar10', router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        target="_blank"
                                                        rel="noopener"
                                                        v-else
                                                    ><span class="dac-text__alert">再入札する</span></a>
                                                </template>
                                                <template v-else-if="router.market === 'gtld'">
                                                    <a
                                                        class="dac-btn-secondary--sm"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        v-if="item.hold === 1"
                                                    >更新する</a>
                                                    <a
                                                        class="dac-btn-secondary--sm"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        v-else
                                                    ><span class="dac-text__alert">再入札する</span></a>
                                                </template>
                                                <template v-else-if="router.market === 'other'">
                                                    <a
                                                        class="dac-btn-secondary--sm"
                                                        @click="onclickEvar('eVar10', router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        target="_blank"
                                                        rel="noopener"
                                                        v-if="item.hold === 1"
                                                    >更新する</a>
                                                    <a
                                                        class="dac-btn-secondary--sm"
                                                        @click="onclickEvar('eVar10', router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        target="_blank"
                                                        rel="noopener"
                                                        v-else
                                                    ><span class="dac-text__alert">再入札する</span>
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    <a class="dac-btn-secondary--sm" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')" v-if="item.hold === 1">更新する</a>
                                                    <a class="dac-btn-secondary--sm" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')" v-else><span class="dac-text__alert">再入札する</span></a>
                                                </template>
                                            </td>
                                        </tr>
                                        <tr v-if="0 === bidListItems.length">
                                            <td v-if="router.market === 'jp' || router.market === 'sell' ||  router.market === 'other'" class="dac-panel-table__content-empty--jp" colspan="6">対象のドメインはありません。</td>
                                            <td v-else class="dac-panel-table__content-empty" colspan="6">対象のドメインはありません。</td>
                                        </tr>
                                        <template v-if="(router.market === 'jp' || router.market === 'sell' || router.market === 'other')">
                                            <tr v-if="0 === bidListItems.length">
                                                <td class="dac-panel-table__content-link" colspan="6">
                                                    <a v-if="(router.market === 'jp')" class="dac-breadcrumb__link" :href="'/campaign/auction/?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_201904'">欲しいドメイン名を探す ></a>
                                                    <a v-if="(router.market === 'other')" class="dac-breadcrumb__link" :href="'/campaign/gtldauction/?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_202310'">欲しいドメイン名を探す ></a>
                                                    <a v-if="(router.market === 'sell')" class="dac-breadcrumb__link" :href="'/auc/sell/list?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_201904'">欲しいドメイン名を探す ></a>
                                                </td>
                                            </tr>
                                        </template>
                                        </tbody>
                                    </table>
                                </div><!-- /.dac-panel -->
                                <p class="dac-text--md">表示価格は「税込価格」です。</p>
                                <PaginationModule
                                    :pageNo="bidPageNo"
                                    :totalPage="bidTotalPage"
                                    :perPage="5"
                                    @changePage="bidChangePage"
                                ></PaginationModule>
                                <div class="dac-tab-content__note" v-if="router.market === 'jp'">
                                    <h4 class="dac-tab-content__note__heading">お支払い方法について</h4>
                                    <p class="dac-tab-content__note__content">
                                        クレジットカードの上限金額がご心配な方は、支払い方法の変更を検討させていただきます。<br>
                                    </p>
                                    <div class="dac-tab-content__note__inquiry">
                                        <p class="dac-tab-content__note__inquiry__text">
                                            <a class="dac-tab-content__note__inquiry__text--link" href="https://help.onamae.com/contact/" target="_blank" rel="noopener">
                                                お問い合わせフォーム
                                            </a>
                                            より、以下内容をご選択のうえご連絡をお願いいたします。 
                                        </p>
                                        <ul class="dac-breadcrumb__mypage">
                                            <li class="dac-breadcrumb__mypage--item">
                                                カテゴリ「ドメイン」
                                            </li>
                                            <li class="dac-breadcrumb__mypage--item">
                                                項目「その他_オークション/バックオーダー
                                            </li>
                                        </ul>
                                        <p class="dac-tab-content__note__inquiry__text">
                                            ▼お問い合わせフォーム遷移後
                                        </p>
                                        <p class="dac-tab-content__note__inquiry__text">
                                            お名前ID：（お客様のお名前ID）<br>
                                            ドメイン名：（オークションドメイン名）<br>
                                            問い合わせ内容：オークションの支払い方法について確認<br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="is-sp">
                                <div class="dac-panel-empty" v-if="0 === bidListItems.length">
                                    対象のドメインはありません。
                                    <template v-if="(router.market === 'jp' || router.market === 'sell')">
                                        <div class="dac-panel-link">
                                            <a v-if="(router.market === 'jp')" class="dac-breadcrumb__link" :href="'/sp/campaign/auction/?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_201904'">欲しいドメイン名を探す ></a>
                                            <a v-if="(router.market === 'other')" class="dac-breadcrumb__link" :href="'/sp/campaign/gtldauction/?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_202310'">欲しいドメイン名を探す ></a>
                                            <a v-if="(router.market === 'sell')" class="dac-breadcrumb__link" :href="'/auc/sell/list?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_201904'">欲しいドメイン名を探す ></a>
                                        </div>
                                    </template>
                                </div>
                                <div class="dac-list-mypage">
                                    <div :class="['dac-list-mypage__item', item.extensionCount > 0 ? 'is-extension' : '']"  v-for="item in bidListItems">
                                        <template v-if="router.market === 'jp' || router.market === 'gtld'">
                                            <a
                                                class="dac-list-mypage__heading"
                                                :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_domain_201904')"
                                            >{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                        </template>
                                        <template v-else>
                                            <a class="dac-list-mypage__heading" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_domain_201904')">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                        </template>
                                        <div class="dac-list-mypage__body">
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">現在</span><span class="dac-list-mypage__num">{{ numberFormat(item.currentPrice) }}円</span></p>
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">入札</span><span class="dac-list-mypage__num">{{ numberFormat(item.bidCount) }}件</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__detail" v-if="router.market !== 'gtld' && item.extensionCount > 0">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">残り</span><span class="dac-list-mypage__num--alert">{{ remainMinutes(item.timeleft) }}</span></p>
                                                </div>
                                            </div>
                                            <div class="dac-list-mypage__detail" v-if="router.market === 'gtld'">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">期限</span><span class="dac-list-mypage__num">{{ convertJpFullDateFormat(item.endDate) }}</span></p>
                                                    <p class="dac-list-mypage__text" v-if="item.extensionCount > 0"><span class="dac-list-mypage__label">残り</span><span class="dac-list-mypage__num--alert">{{ remainMinutes(item.timeleft) }}</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__footer">
                                                <div class="dac-label is-winning" v-if="item.hold === 1">最高入札者<span class="dac-text__winning"><i class="dac-ico-win"></i>{{ hold(item.hold) }}</span></div>
                                                <div class="dac-label is-lose" v-else>最高入札者<span class="dac-text__alert"><i class="dac-ico-lose"></i>{{ hold(item.hold) }}</span></div>
                                                <template v-if="router.market === 'jp' || router.market === 'gtld'">
                                                    <a
                                                        class="dac-btn-secondary--max"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        @click="fireEvent()"
                                                        v-if="item.hold === 1"
                                                    >更新する</a>
                                                    <a
                                                        class="dac-btn-secondary--max"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')"
                                                        @click="fireEvent()"
                                                        v-else
                                                    ><span class="dac-text__alert">再入札する</span></a>
                                                </template>
                                                <template v-else>
                                                    <a class="dac-btn-secondary--max" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')" v-if="item.hold === 1">更新する</a>
                                                    <a class="dac-btn-secondary--max" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_bidlist_bid_button_201904')" v-else><span class="dac-text__alert">再入札する</span></a>
                                                </template>
                                            </div><!-- /.dac-list-mypage__footer -->
                                        </div>
                                    </div>
                                    <p class="dac-text--sm">表示価格は「税込価格」です。</p>
                                </div><!-- /.dac-list-mypage -->
                                <PaginationModule
                                    :pageNo="bidPageNo"
                                    :totalPage="bidTotalPage"
                                    :perPage="3"
                                    @changePage="bidChangePage"
                                ></PaginationModule>
                                <div class="dac-tab-content__note" v-if="router.market === 'jp'">
                                    <h4 class="dac-tab-content__note__heading">お支払い方法について</h4>
                                    <p class="dac-tab-content__note__content">
                                        クレジットカードの上限金額がご心配な方は、支払い方法の変更を検討させていただきます。<br>
                                    </p>
                                    <div class="dac-tab-content__note__inquiry">
                                        <p class="dac-tab-content__note__inquiry__text">
                                            <a class="dac-tab-content__note__inquiry__text--link" href="https://help.onamae.com/contact/" target="_blank" rel="noopener">お問い合わせフォーム</a>より、以下内容をご選択のうえご連絡をお願いいたします。 
                                        </p>
                                        <ul class="dac-breadcrumb__mypage">
                                            <li class="dac-breadcrumb__mypage--item">
                                                カテゴリ「ドメイン」
                                            </li>
                                            <li class="dac-breadcrumb__mypage--item" >
                                                項目「その他_オークション/バックオーダー
                                            </li>
                                        </ul>
                                        <p class="dac-tab-content__note__inquiry__text">
                                            ▼お問い合わせフォーム遷移後
                                        </p>
                                        <p class="dac-tab-content__note__inquiry__text">
                                            お名前ID：（お客様のお名前ID）<br>
                                            ドメイン名：（オークションドメイン名）<br>
                                            問い合わせ内容：オークションの支払い方法について確認<br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="dac-tab-content__item" v-else-if="isTabActive === '2' || isTabActive === '6'">
                            <div class="is-pc">
                                <div :class="['dac-panel', (0 === watchListItems.length) ? 'is-flat' : '']">
                                    <table class="dac-panel-table--sort">
                                        <thead class="dac-panel-table__header--primary">
                                        <tr>
                                            <th class="dac-panel-table__heading--check">
                                                <label class="dac-form-check__label" for="checkbox-table" v-if="0 < watchListItems.length">
                                                    <input
                                                        class="dac-form-check"
                                                        type="checkbox"
                                                        id="checkbox-table"
                                                        v-model="checkedSelectAll"
                                                        @change="selectAll"
                                                        @click="onclickEvar('eVar10', router.market + 'auc_mypage_watchlist_watchdelete_check_all_onclick_201904')"
                                                    ><i class="dac-ico-check"></i>
                                                </label>
                                            </th>

                                            <th v-for="(sortLabel, key) in watchListSortLabels" :class="modifierClass(sortLabel)">
                                                <template v-if="0 === watchListItems.length">
                                                    {{ sortLabel }}
                                                </template>
                                                <template v-else>
                                                    <a
                                                        :class="['dac-panel-table__trigger', watchListSortType === '1' ? 'is-current' : '']"
                                                        href="javascript: void(0);"
                                                        @click="sortWatchList(key);onclickEvar('eVar10', router.market + 'auc_mypage_watchilist_sort_' + key + '_onclick_' + ( router.market === 'other' ? '202310' : '201904'))"
                                                    >
                                                        {{ sortLabel }}
                                                        <i class="dac-ico-sort" v-if="watchListSortKey === key"></i>
                                                    </a>
                                                </template>
                                            </th>
                                            <th class="dac-panel-table__heading--btn"></th>
                                        </tr>
                                        </thead>
                                        <tbody class="dac-panel-table__body">
                                        <tr v-for="item in watchListItems">
                                            <th class="dac-panel-table__content">
                                                <label class="dac-form-check__label" :for="'checkbox' + item.aucItemId">
                                                    <input
                                                        class="dac-form-check"
                                                        type="checkbox"
                                                        :id="'checkbox' + item.aucItemId"
                                                        :value="item.aucItemId"
                                                        v-model="checkedAucItemId"
                                                        @click="onclickEvar('eVar10', router.market + 'auc_mypage_watchlist_watchdelete_check_onclick_201904')"
                                                    ><i class="dac-ico-check"></i>
                                                </label>
                                            </th>
                                            <td class="dac-panel-table__content">
                                                <template v-if="router.market === 'jp'">
                                                    <a
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_domain_201904')"
                                                        class="dac-panel-table__id"
                                                    >{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                                    <p class="dac-text--sm"><span class="dac-text__alert">{{ item.means }}</span></p>
                                                </template>
                                                <template v-else>
                                                    <template v-if="router.market === 'jp' || router.market === 'gtld'">
                                                        <a
                                                            :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_domain_201904')"
                                                            class="dac-panel-table__id"
                                                        >{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                                    </template>
                                                    <template v-else-if="router.market === 'other'">
                                                        <a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_domain_201904')" class="dac-panel-table__id">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                                    </template>
                                                    <template v-else>
                                                        <a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_domain_201904')" class="dac-panel-table__id">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                                    </template>
                                                </template>
                                            </td>
                                            <td class="dac-panel-table__content">¥<span class="dac-panel-table__price">{{ numberFormat(item.currentPrice) }}</span></td>
                                            <td class="dac-panel-table__content">
                                                <span class="dac-panel-table__num" v-if="item.trafficType === 1">----</span>
                                                <span class="dac-panel-table__num" v-else>{{ numberFormat(item.bidCount) }}</span>
                                            </td>
                                            <td class="dac-panel-table__content">
                                                <template v-if="router.market === 'jp' || router.market === 'other'">
                                                    <span class="dac-table-list__date" v-if="convertTimeLeft(item.timeleft)">
                                                        <template v-if="convertTimeLeft(item.timeleft).day !== '0'">
                                                            {{ convertTimeLeft(item.timeleft).day }}<span class="dac-table-list__unit">日</span>
                                                        </template>
                                                        <template v-if="convertTimeLeft(item.timeleft).hour !== '0'">
                                                            {{ convertTimeLeft(item.timeleft).hour }}<span class="dac-table-list__unit">時間</span>
                                                        </template>
                                                        <template v-if="convertTimeLeft(item.timeleft).minute !== '0'">
                                                            {{ convertTimeLeft(item.timeleft).minute }}<span class="dac-table-list__unit">分</span>
                                                        </template>
                                                        <template v-if="convertTimeLeft(item.timeleft).seconds !== '0'">
                                                            {{ convertTimeLeft(item.timeleft).seconds }}<span class="dac-table-list__unit">秒</span>
                                                        </template>
                                                    </span>
                                                    <span class="dac-table-list__date" v-else>-</span>
                                                </template>
                                                <template v-else>
                                                    <template v-if="
                                                                router.market !== 'other' ||
                                                                !isItemTimeLeftLimit(item.timeleft)
                                                            ">
                                                        <span class="dac-panel-table__num">{{ convertJpDateFormat(item.endDate) }}</span>
                                                        <span class="dac-panel-table__time">{{ convertJpTimeFormat(item.endDate) }}</span>
                                                    </template>
                                                    <div class="dac-panel-table__time--limit" v-if="
                                                                router.market === 'other' &&
                                                                isItemTimeLeftLimit(item.timeleft)
                                                            ">
                                                        <span>残り時間：</span>
                                                        <span class="dac-text__alert">{{ convertItemTimeLeft(item.timeleft) }}</span>
                                                    </div>
                                                </template>
                                            </td>
                                            <td class="dac-panel-table__content">
                                                <template v-if="router.market === 'jp'">
                                                    <a
                                                        class="dac-btn-secondary--sm"
                                                        @click="fireEvent();onclickEvar('eVar10', router.market + 'auc_mypage_watchlist_bid_button_201904')"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_button_201904')"
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <template v-if="item.trafficType === 1">
                                                            申し込む
                                                        </template>
                                                        <template v-else>
                                                            入札状況を<br>見る
                                                        </template>
                                                    </a>
                                                </template>
                                                <template v-else-if="router.market === 'gtld'">
                                                    <a class="dac-btn-secondary--sm" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_button_201904')">
                                                        <template v-if="item.trafficType === 1">
                                                            申し込む
                                                        </template>
                                                        <template v-else>
                                                            入札状況を<br>見る
                                                        </template>
                                                    </a>
                                                </template>
                                                <template v-else-if="router.market === 'other'">
                                                    <a
                                                        class="dac-btn-secondary--sm"
                                                        @click="onclickEvar('eVar10', router.market + 'auc_mypage_watchlist_bid_button_201904')"
                                                        :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_button_201904')"
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <template v-if="item.trafficType === 1">
                                                            申し込む
                                                        </template>
                                                        <template v-else>
                                                            入札状況を<br>見る
                                                        </template>
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    <a class="dac-btn-secondary--sm" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_button_201904')">
                                                        <template v-if="item.trafficType === 1">
                                                            申し込む
                                                        </template>
                                                        <template v-else>
                                                            入札状況を<br>見る
                                                        </template>
                                                    </a>
                                                </template>
                                            </td>
                                        </tr>
                                        <tr v-if="0 === watchListItems.length">
                                            <td v-if="router.market === 'jp' || router.market === 'sell' || router.market === 'other'" class="dac-panel-table__content-empty--jp"  colspan="6">対象のドメインはありません。</td>
                                            <td v-else class="dac-panel-table__content-empty"  colspan="6">対象のドメインはありません。</td>
                                        </tr>
                                        <template v-if="(router.market === 'jp' || router.market === 'sell' || router.market === 'other')">
                                            <tr v-if="0 === watchListItems.length">
                                                <td class="dac-panel-table__content-link" colspan="6">
                                                    <a v-if="(router.market === 'jp')" class="dac-breadcrumb__link" :href="'/campaign/auction/?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_201904'">欲しいドメイン名を探す ></a>
                                                    <a v-if="(router.market === 'other')" class="dac-breadcrumb__link" :href="'/campaign/gtldauction/?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_202310'">欲しいドメイン名を探す ></a>
                                                    <a v-if="(router.market === 'sell')" class="dac-breadcrumb__link" :href="'/auc/sell/list?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_201904'">欲しいドメイン名を探す ></a>
                                                </td>
                                            </tr>
                                        </template>
                                        </tbody>
                                    </table>
                                </div><!-- /.dac-panel -->
                                <p class="dac-text--md">表示価格は「税込価格」です。</p>
                                <template v-if="watchListItems.length > 0">
                                    <div class="dac-layout-stackable">
                                        <div class="dac-layout-stackable__inner">
                                            <button
                                                type="button"
                                                :class="['dac-btn-primary--md-row', disabledDeleteWatchListButton ? 'is-disabled' : '']"
                                                :disabled="disabledDeleteWatchListButton"
                                                @click="deleteWatchList();onclickEvar('eVar10', router.market + 'auc_mypage_watchlist_watchdelete_complete_onclick_201904')"
                                            ><i class="dac-ico-dustbox"></i>ウォッチリストから削除</button>
                                        </div>
                                    </div>
                                </template>
                                <PaginationModule
                                    :pageNo="watchListPageNo"
                                    :totalPage="watchListTotalPage"
                                    :perPage="5"
                                    @changePage="watchListChangePage"
                                ></PaginationModule>
                            </div>
                            <div class="is-sp">
                                <div class="dac-panel-empty" v-if="0 === watchListItems.length">
                                    対象のドメインはありません。
                                    <template v-if="(router.market === 'jp' || router.market === 'sell' || router.market === 'other' )">
                                        <div class="dac-panel-link">
                                            <a v-if="(router.market === 'jp')" class="dac-breadcrumb__link" :href="'/sp/campaign/auction/?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_201904'">欲しいドメイン名を探す ></a>
                                            <a v-if="(router.market === 'other')" class="dac-breadcrumb__link" :href="'/sp/campaign/gtldauction/?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_202310'">欲しいドメイン名を探す ></a>
                                            <a v-if="(router.market === 'sell')" class="dac-breadcrumb__link" :href="'/auc/sell/list?btn_id=' + router.market + 'auc_mypage_' + pageName + '_nodomainlist_top_201904'">欲しいドメイン名を探す ></a>
                                        </div>
                                    </template>
                                </div>
                                <div class="dac-list-mypage">
                                    <div :class="['dac-list-mypage__item', item.extensionCount > 0 ? 'is-extension' : '']" v-for="item in watchListItems">
                                        <a
                                            href="javascript: void(0);"
                                            class="dac-list-mypage__close"
                                            @click="deleteWatchListModal(item.aucItemId);onclickEvar('eVar10', router.market + 'auc_mypage_watchlist_watchdelete_check_onclick_201904')"
                                        ></a>
                                        <template v-if="router.market === 'jp' || router.market === 'gtld'">
                                            <a
                                                class="dac-list-mypage__heading"
                                                :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_domain_201904')"
                                            >{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                        </template>
                                        <template v-else>
                                            <a class="dac-list-mypage__heading" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_domain_201904')">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                        </template>
                                        <div class="dac-list-mypage__body">
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">現在</span><span class="dac-list-mypage__num">{{ numberFormat(item.currentPrice) }}円</span></p>
                                                    <p class="dac-list-mypage__text">
                                                        <span class="dac-list-mypage__label">入札</span><span class="dac-list-mypage__num" v-if="item.trafficType === 1">----</span><span class="dac-list-mypage__num" v-else>{{ numberFormat(item.bidCount) }}件</span>
                                                    </p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__detail" v-if="router.market !== 'gtld' && item.extensionCount > 0">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">残り</span><span class="dac-list-mypage__num--alert">{{ remainMinutes(item.timeleft) }}</span></p>
                                                </div>
                                            </div>
                                            <div class="dac-list-mypage__detail" v-if="router.market === 'gtld'">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">期限</span><span class="dac-list-mypage__num">{{ convertJpFullDateFormat(item.endDate) }}</span></p>
                                                    <p class="dac-list-mypage__text" v-if="item.extensionCount > 0"><span class="dac-list-mypage__label">残り</span><span class="dac-list-mypage__num--alert">{{ remainMinutes(item.timeleft) }}</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__footer">
                                                <template v-if="router.market === 'jp' || router.market === 'gtld'">
                                                    <a class="dac-btn-secondary--max" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_button_201904')" @click="fireEvent()">
                                                        <template v-if="item.trafficType === 1">
                                                            申し込む
                                                        </template>
                                                        <template v-else>
                                                            入札状況を見る
                                                        </template>
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    <a class="dac-btn-secondary--max" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_watchlist_bid_button_201904')">
                                                        <template v-if="item.trafficType === 1">
                                                            申し込む
                                                        </template>
                                                        <template v-else>
                                                            入札状況を見る
                                                        </template>
                                                    </a>
                                                </template>
                                            </div><!-- /.dac-list-mypage__footer -->
                                        </div>
                                    </div>
                                    <p class="dac-text--sm">表示価格は「税込価格」です。</p>
                                </div><!-- /.dac-list-mypage -->
                                <PaginationModule
                                    :pageNo="watchListPageNo"
                                    :totalPage="watchListTotalPage"
                                    :perPage="3"
                                    @changePage="watchListChangePage"
                                ></PaginationModule>
                            </div>
                            <div
                                class="dac-carousel"
                                v-if="0 === watchListItems.length && 0 !== recommendItems.length && (router.market === 'jp' || router.market === 'other')"
                            >
                                <h2 class="dac-heading2--center">今月の注目ドメイン</h2>
                                <ul class="dac-carousel-list">
                                    <li class="dac-carousel-list__item" v-for="item in recommendItems">
                                        <div class="dac-carousel-content">
                                            <h5 class="dac-heading5"><span class="dac-carousel__text">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</span></h5>
                                            <p class="dac-text--xs"><span class="dac-carousel__text">{{ item.means }}</span></p>
                                            <a
                                                :href="linkUrl('/detail/' + item.itemId + '?btn_id=' + router.market + 'auctionwatchlistrecommend_'+ item.domainName + '_nowatch')"
                                                class="dac-btn-secondary--md"
                                            >
                                                <template v-if="item.trafficType === 1">
                                                    申し込む
                                                </template>
                                                <template v-else>
                                                    入札状況を見る
                                                </template>
                                            </a>
                                        </div>
                                    </li>
                                </ul><!-- /.dac-carousel-list -->
                            </div><!-- /.dac-carousel -->
                        </li>
                        <li class="dac-tab-content__item" v-else-if="isTabActive === '3'">
                            <div class="is-pc">
                                <div :class="['dac-panel', (0 === offerListItems.length) ? 'is-flat' : '']">
                                    <table class="dac-panel-table--sort">
                                        <thead class="dac-panel-table__header--primary">
                                        <tr>
                                            <th v-for="(sortLabel, key) in offerListSortLabels" :class="modifierClass(sortLabel)">
                                                <template v-if="0 === offerListItems.length">
                                                    {{ sortLabel }}
                                                </template>
                                                <template v-else>
                                                    <a
                                                        :class="['dac-panel-table__trigger', offerListSortType === '1' ? 'is-current' : '']"
                                                        href="javascript: void(0);"
                                                        @click="sortOfferList(key);onclickEvar('eVar10', router.market + 'auc_mypage_offerlist_sort_' + key + '_onclick_201904')"
                                                    >
                                                        {{ sortLabel }}
                                                        <i class="dac-ico-sort" v-if="offerListSortKey === key"></i>
                                                    </a>
                                                </template>
                                            </th>
                                            <th class="dac-panel-table__heading--btn" v-if="offerListItems.length > 0"></th>
                                        </tr>
                                        </thead>
                                        <tbody class="dac-panel-table__body">
                                        <tr v-for="item in offerListItems">
                                            <td class="dac-panel-table__content"><a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_offerlist_domain_201904')" class="dac-panel-table__id">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a></td>
                                            <td class="dac-panel-table__content">¥<span class="dac-panel-table__price">{{ numberFormat(item.offerPrice) }}</span></td>
                                            <td class="dac-panel-table__content"><span class="dac-panel-table__num">{{ convertJpDateFormat(item.endDate) }}</span><span class="dac-panel-table__time">{{ convertJpTimeFormat(item.endDate) }}</span></td>
                                            <td class="dac-panel-table__content"><span class="dac-panel-table__text">{{ offerStatusLabel(item.status) }}</span></td>
                                            <td class="dac-panel-table__content">
                                                <template v-if="item.reOfferDate"><span class="dac-panel-table__num">{{ convertJpDateFormat(item.reOfferDate) }}</span><span class="dac-panel-table__time">{{ convertJpTimeFormat(item.reOfferDate) }}</span></template>
                                                <span class="dac-panel-table__num" v-else>-</span>
                                            </td>
                                            <td class="dac-panel-table__content" v-if="item.reOfferPrice">¥<span class="dac-panel-table__price">{{ numberFormat(item.reOfferPrice) }}</span></td>
                                            <td class="dac-panel-table__content" v-else><span class="dac-panel-table__text">----</span></td>
                                            <td class="dac-panel-table__content">
                                                <a class="dac-btn-secondary--sm" v-if="item.status === 'DENY'" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_offerlist_reoffer_button_201904')">再申込</a>
                                            </td>
                                        </tr>
                                        <tr v-if="0 === offerListItems.length">
                                            <td class="dac-panel-table__content-empty" colspan="6">対象のドメインはありません。</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div><!-- /.dac-panel -->
                                <p class="dac-text--md">表示価格は「税込価格」です。</p>
                                <PaginationModule
                                    :pageNo="offerListPageNo"
                                    :totalPage="offerListTotalPage"
                                    :perPage="5"
                                    @changePage="offerListChangePage"
                                ></PaginationModule>
                            </div>
                            <div class="is-sp">
                                <div class="dac-panel-empty" v-if="0 === offerListItems.length">
                                    対象のドメインはありません。
                                </div>
                                <div class="dac-list-mypage">
                                    <div class="dac-list-mypage__item" v-for="item in offerListItems">
                                        <a class="dac-list-mypage__heading" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_offerlist_domain_201904')">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a>
                                        <div class="dac-list-mypage__body">
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">価格</span><span class="dac-list-mypage__num">{{ numberFormat(item.offerPrice) }}円</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">状況</span><span class="dac-list-mypage__num">{{ offerStatusLabel(item.status) }}</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">期限</span><span class="dac-list-mypage__num">{{ convertJpFullDateFormat(item.endDate) }}</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">拒否日時</span><span class="dac-list-mypage__num">{{ convertJpFullDateFormat((item.reOfferDate) ? item.reOfferDate : '-') }}</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">出品者からの提示額</span><span class="dac-list-mypage__num" v-if="item.reOfferPrice">¥{{ numberFormat(item.reOfferPrice) }}</span>
                                                        <span class="dac-list-mypage__num" v-else>----</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__footer" v-if="item.status === 'DENY'">
                                                <a class="dac-btn-secondary--max" :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_offerlist_reoffer_button_201904')">再申込</a>
                                            </div><!-- /.dac-list-mypage__footer -->
                                        </div>
                                    </div>
                                    <p class="dac-text--sm">表示価格は「税込価格」です。</p>
                                </div><!-- /.dac-list-mypage -->
                                <PaginationModule
                                    :pageNo="offerListPageNo"
                                    :totalPage="offerListTotalPage"
                                    :perPage="3"
                                    @changePage="offerListChangePage"
                                ></PaginationModule>
                            </div>
                        </li>
                        <li class="dac-tab-content__item" v-else-if="isTabActive === '4'">
                            <div class="is-pc">
                                <div :class="['dac-panel', (0 === winBidListItems.length) ? 'is-flat' : '']">
                                    <table class="dac-panel-table--sort">
                                        <thead class="dac-panel-table__header--primary">
                                        <tr>
                                            <th v-for="(sortLabel, key) in winBidSortLabels" :class="modifierClass(sortLabel)">
                                                <template v-if="0 === winBidListItems.length">
                                                    {{ sortLabel }}
                                                </template>
                                                <template v-else>
                                                    <a
                                                        :class="['dac-panel-table__trigger', winBidSortType === '1' ? 'is-current' : '']"
                                                        href="javascript: void(0);"
                                                        @click="sortWinBidList(key);onclickEvar('eVar10', router.market + 'auc_mypage_winbidlist_sort_' + key + '_onclick_201904')"
                                                    >
                                                        {{ sortLabel }}
                                                        <i class="dac-ico-sort" v-if="winBidSortKey === key"></i>
                                                    </a>
                                                </template>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody class="dac-panel-table__body">
                                        <tr v-for="item in winBidListItems">
                                            <td class="dac-panel-table__content"><a :href="linkUrl('/detail/' + item.aucItemId + '?btn_id=' + router.market + 'auc_mypage_winbidlist_domain_201904')" class="dac-panel-table__id">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</a></td>
                                            <td class="dac-panel-table__content"><span class="dac-panel-table__num">{{ trafficTypeLabel(item.trafficType) }}</span></td>
                                            <td class="dac-panel-table__content">¥<span class="dac-panel-table__price">{{ numberFormat(item.currentPrice) }}</span></td>
                                            <td class="dac-panel-table__content"><span class="dac-panel-table__num">{{ convertJpDateFormat(item.endDate) }}</span><span class="dac-panel-table__time">{{ convertJpTimeFormat(item.endDate) }}</span></td>
                                        </tr>
                                        <tr v-if="0 === winBidListItems.length">
                                            <td class="dac-panel-table__content-empty" colspan="4">対象のドメインはありません。</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div><!-- /.dac-panel -->
                                <p class="dac-text--md">表示価格は「税込価格」です。</p>
                                <PaginationModule
                                    :pageNo="winBidPageNo"
                                    :totalPage="winBidListTotalPage"
                                    :perPage="5"
                                    @changePage="winBidListChangePage"
                                ></PaginationModule>
                            </div>
                            <div class="is-sp">
                                <div class="dac-panel-empty" v-if="0 === winBidListItems.length">
                                    対象のドメインはありません。
                                </div>
                                <div class="dac-list-mypage">
                                    <div class="dac-list-mypage__item" v-for="item in winBidListItems">
                                        <div class="dac-list-mypage__heading">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</div>
                                        <div class="dac-list-mypage__body">
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">購入価格</span><span class="dac-list-mypage__num">{{ numberFormat(item.currentPrice) }}円</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">種類</span><span class="dac-list-mypage__num">{{ trafficTypeLabel(item.trafficType) }}</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                            <div class="dac-list-mypage__detail">
                                                <div class="dac-list-mypage__detail-inner">
                                                    <p class="dac-list-mypage__text"><span class="dac-list-mypage__label">終了日</span><span class="dac-list-mypage__num">{{ convertJpFullDateFormat(item.endDate) }}</span></p>
                                                </div>
                                            </div><!-- /.dac-list-mypage__detail -->
                                        </div>
                                    </div>
                                    <p class="dac-text--sm">表示価格は「税込価格」です。</p>
                                </div><!-- /.dac-list-mypage -->
                                <PaginationModule
                                    :pageNo="winBidPageNo"
                                    :totalPage="winBidListTotalPage"
                                    :perPage="3"
                                    @changePage="winBidListChangePage"
                                ></PaginationModule>
                            </div>
                        </li>
                    </ul><!-- /.dac-tab-content -->
                </div><!-- /.dac-section__inner -->
            </section><!-- /.dac-section -->
        </article><!-- /.dac-article -->
    </main><!-- /.dac-main -->
    <aside class="dac-modal--sm" :class="{ 'is-fixed': isWatchListModal }">
        <div class="dac-modal__content">
            <p class="dac-text--center">
                ウォッチリストから<span class="dac-text__alert">消去</span>します。<br>よろしいですか？
            </p>
            <div class="dac-layout-column--2">
                <div class="dac-layout-column__item">
                    <button
                        class="dac-btn--max"
                        @click="deleteWatchListItem(selectedItemId);onclickEvar('eVar10', router.market + 'auc_mypage_watchlist_watchdelete_yes_onclick_201904')"
                    >はい</button>
                </div>
                <div class="dac-layout-column__item">
                    <button
                        class="dac-btn-primary"
                        @click="deleteWatchListModal();onclickEvar('eVar10', router.market + 'auc_mypage_watchlist_watchdelete_no_onclick_201904')"
                    >いいえ</button>
                </div>
            </div><!-- /.dac-layout-column--2 -->
        </div>
    </aside><!-- /.dac-modal -->
    <div class="dac-modal__overlay" @click="deleteWatchListModal" :class="{ 'is-fixed': isWatchListModal }"></div>
    <aside class="dac-modal" :class="{ 'is-fixed': isBidDescribe }">
        <div class="dac-modal__content">
            <a
                href="javascript: void(0);"
                class="dac-modal-close"
                @click="bidModalActive();onclickEvar('eVar10', router.market + 'auc_detail_automaticbid_close_onclick_201904')"
            ><span class="dac-modal-close__icon"></span></a>
            <div class="dac-modal__heading">
                <h3 class="dac-heading-point-secondary--inline">自動入札とは？</h3>
            </div><!-- /.dac-modal__heading -->
            <div class="dac-modal__row">
                <div class="dac-note--shadow">
                    <p class="dac-text--md">予算の上限で入札しておくと他の人が入札してきた場合に、<br class="is-pc">上限金額の範囲内で自動的に再入札をしてくれる仕組みのことです。</p>
                </div><!-- /.dac-note -->
                <ul class="dac-text-indent--md">
                    <li class="dac-text-indent__item"><span class="dac-text-indent__img"><img :src="assets('images/img_merit01.png')" alt="メリット1" /></span>予算内の再入札は自動更新のため、価格確認・入札の手間が省ける！</li>
                    <li class="dac-text-indent__item"><span class="dac-text-indent__img"><img :src="assets('images/img_merit02.png')" alt="メリット2" /></span>価格更新が確認できなかった場合など、再入札漏れが防げる！</li>
                </ul>
            </div><!-- /.dac-modal__row -->
            <div class="dac-modal__heading">
                <h3 class="dac-heading-point-secondary--inline">自動入札の方法</h3>
            </div><!-- /.dac-modal__heading -->
            <div class="dac-modal-image__flow" v-if="router.market === 'jp'">
                <img class="is-vertical" :src="assets('images/img_jp-auc-flow.png')" alt="自動入札の方法" />
                <img class="is-stackable" :src="assets('images/img_jp-auc-flow-sp.png')" alt="自動入札の方法" />
            </div><!-- /.dac-modal__image -->
            <div class="dac-modal-image__flow" v-if="router.market === 'gtld'">
                <img class="is-vertical" :src="assets('images/img_bid-flow.png')" alt="自動入札の方法" />
                <img class="is-stackable" :src="assets('images/img_bid-flow-sp.png')" alt="自動入札の方法" />
            </div><!-- /.dac-modal__image -->
        </div>
    </aside><!-- /.dac-modal -->
    <div class="dac-modal__overlay" @click="bidModalActive()" :class="{ 'is-fixed': isBidDescribe }"></div>
</div>
</template>